.tablesHeader {
    background-color: #e0e7ff;
  }
  
  .custom-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e7ff;
    padding: 10px;
  }
  
  .custom-pagination .MuiTablePagination-displayedRows {
    margin-top: 1rem;
  }